import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

const Homepage = lazy(async () => await import('./pages/homepage'));
const TermsOfService = lazy(async () => await import('./pages/terms-of-service'));
const PrivacyPolicy = lazy(async () => await import('./pages/privacy-policy'));
const RefundPolicy = lazy(async () => await import('./pages/refund-policy'));
const ShippingPolicy = lazy(async () => await import('./pages/shipping-policy'));
const Contact = lazy(async () => await import('./pages/contact'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={<Homepage />}
      />
      <Route
        path="/terms-of-service"
        element={<TermsOfService />}
      />
      <Route
        path="/privacy-policy"
        element={<PrivacyPolicy />}
      />
      <Route
        path="/refund-policy"
        element={<RefundPolicy />}
      />
      <Route
        path="/shipping-policy"
        element={<ShippingPolicy />}
      />
      <Route
        path="/contact"
        element={<Contact />}
      />
      <Route
        path="*"
        element={<Homepage />}
      />
    </>,
  ),
);

const Loading = () => (
  <div className="bg-grey-5 text-grey-90 flex h-screen w-full items-center justify-center">
    <div
      className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  </div>
);

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
