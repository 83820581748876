import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const supportedLanguages = [
  {
    locale: 'en',
    name: 'English',
  },
  {
    locale: 'ko',
    name: '한국어',
  },
].sort((a, b) => a.locale.localeCompare(b.locale));

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ['common'],
    defaultNS: 'common',
    backend: {
      loadPath: 'translations/{{lng}}/{{ns}}.json',
    },
    supportedLngs: supportedLanguages.map((l) => l.locale),
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
